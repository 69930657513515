<template>
  <div>
    <slot />
    <SpeedInsights/>
  </div>
</template>

<script setup>
import { SpeedInsights } from "@vercel/speed-insights/nuxt"
const route = useRoute();
const currentUrl = useRequestURL().href;

useSeoMeta({
  lang: 'en',
  title: route.meta.title,
  titleTemplate: (title) => `${title} - Lifeboost Coffee`,
  viewport: 'width=device-width, initial-scale=1',
  charset: 'utf-8',
  meta: [
    { name: 'description', content: route.meta.description },
    { name: 'title', content: route.meta.title },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: currentUrl },
    { property: 'og:title', content: currentUrl },
    { property: 'og:description', content: route.meta.description },
    { property: 'og:image', content: 'https://sale.lifeboostcoffee.com/assets/images/LBC-OG.jpg' },
    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:url', content: currentUrl },
    { property: 'twitter:title', content: route.meta.title },
    { property: 'twitter:description', content: route.meta.description },
    { property: 'twitter:image', content: 'https://sale.lifeboostcoffee.com/assets/images/LBC-OG.jpg' }
  ],
  link: [
    { rel: 'canonical', href: currentUrl },
    { rel: 'preconnect', href: 'https://sale.lifeboostcoffee.com' },
    { rel: 'icon', type: 'image/x-icon', href: 'https://sale.lifeboostcoffee.com/favicon.ico' },
    { rel: 'icon', type: 'image/png', href: 'https://sale.lifeboostcoffee.com/favicon_32x32.png', sizes: '32x32' },
    { rel: 'icon', type: 'image/png', href: 'https://sale.lifeboostcoffee.com/favicon_16x16.webp', sizes: '16x16' },
    { rel: 'icon', type: 'image/png', href: 'https://sale.lifeboostcoffee.com/favicon_8x8.webp', sizes: '8x8' },
    { rel: 'shortcut icon', href: 'https://sale.lifeboostcoffee.com/favicon.ico' },
  ],
  'theme-color': '#f1574b',
})

const gtm = useGtm();

const trackEvent = (trackObj) => {
  gtm.trackEvent(trackObj);

};

const trackView = (page, route) => {
  gtm.trackView(page, route)
};

provide('trackEvent', (trackObj) => { trackEvent(trackObj) });

if(import.meta.server){
  useShutWarn()
}

onMounted(() => {
  nextTick(() => {
    trackView(document.title, document.location.pathname);
  })



})
</script>
